




















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Sidebar extends Vue {
	@Prop({ required: false, type: String, default: "apiv2" }) type!: string;
 

 private versionType ="apiv2"

 mounted(){
   this.versionType = this.type
 }

	toggleChildren(nothing: null, event: any) {
		const parent = event.target.parentElement;
		const section = parent.querySelector(".section__list");
		section.classList.toggle("hidden");
	}

  isApi(){
    return this.versionType.startsWith("api")
  }


	@Watch("version")
	changeVersion(newVal: string) {
		this.$router.push({ path: `/api/${newVal}` });
	}
}
