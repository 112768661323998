import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import PassThrough from "../components/PassThrough.vue";
import Api from "../views/Api.vue";
import Ws from "../views/Ws.vue";
import Changelog from "../views/Changelog.vue";
import Examples from "../views/Examples.vue"

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [{
      path:"/api",
      component:Api,
      children:[{
      path: "v1",
     
      component: PassThrough,
      children: [{
      path: "auth",
      
      component: PassThrough,
      children: [{
      path: "account-user",
      component: PassThrough,
      children: [{
      path: "pms-login",
      component: () => import('@/views/Endpoints/V1/Auth/AccountUser/PMSLogin.vue')
  },{
      path: "social-login",
      component: () => import('@/views/Endpoints/V1/Auth/AccountUser/SocialLogin.vue')
  },{
      path: "request-password-reset",
      component: () => import('@/views/Endpoints/V1/Auth/AccountUser/RequestPasswordReset.vue')
  },]
    },{
      path: "user",
      component: PassThrough,
      children: [{
      path: "user-logout",
      component: () => import('@/views/Endpoints/V1/Auth/User/UserLogout.vue')
  },{
      path: "social-login",
      component: () => import('@/views/Endpoints/V1/Auth/User/SocialLogin.vue')
  },]
    },]
    },{
      path: "account",
      
      component: PassThrough,
      children: [{
      path: "list-all-accounts",
      component: () => import('@/views/Endpoints/V1/Account/ListAllAccounts.vue')
  },{
      path: "get-account-by-id",
      component: () => import('@/views/Endpoints/V1/Account/GetAccountById.vue')
  },{
      path: "get-account-users-by-account-id",
      component: () => import('@/views/Endpoints/V1/Account/GetAccountUsersByAccountId.vue')
  },{
      path: "accept-auto-extend",
      component: () => import('@/views/Endpoints/V1/Account/AcceptAutoExtend.vue')
  },{
      path: "update-account-by-id",
      component: () => import('@/views/Endpoints/V1/Account/UpdateAccountById.vue')
  },{
      path: "register-connect-account",
      component: () => import('@/views/Endpoints/V1/Account/RegisterConnectAccount.vue')
  },{
      path: "update-self-account",
      component: () => import('@/views/Endpoints/V1/Account/UpdateSelfAccount.vue')
  },{
      path: "delete-account-by-id",
      component: () => import('@/views/Endpoints/V1/Account/DeleteAccountById.vue')
  },]
    },{
      path: "account-users",
      
      component: PassThrough,
      children: [{
      path: "list-all-account-users",
      component: () => import('@/views/Endpoints/V1/AccountUsers/ListAllAccountUsers.vue')
  },{
      path: "get-account-user-by-id",
      component: () => import('@/views/Endpoints/V1/AccountUsers/GetAccountUserById.vue')
  },{
      path: "update-account-user-by-id",
      component: () => import('@/views/Endpoints/V1/AccountUsers/UpdateAccountUserById.vue')
  },{
      path: "delete-account-user-by-id",
      component: () => import('@/views/Endpoints/V1/AccountUsers/DeleteAccountUserById.vue')
  },{
      path: "get-self-account-user",
      component: () => import('@/views/Endpoints/V1/AccountUsers/GetSelfAccountUser.vue')
  },{
      path: "update-self-account-user",
      component: () => import('@/views/Endpoints/V1/AccountUsers/UpdateSelfAccountUser.vue')
  },{
      path: "account-user-invitation",
      component: () => import('@/views/Endpoints/V1/AccountUsers/AccountUserInvitation.vue')
  },{
      path: "request-email-activation",
      component: () => import('@/views/Endpoints/V1/AccountUsers/RequestEmailActivation.vue')
  },{
      path: "email-activation-confirmation",
      component: () => import('@/views/Endpoints/V1/AccountUsers/EmailActivationConfirmation.vue')
  },{
      path: "password-reset",
      component: () => import('@/views/Endpoints/V1/AccountUsers/PasswordReset.vue')
  },]
    },{
      path: "products",
      
      component: PassThrough,
      children: [{
      path: "product",
      component: PassThrough,
      children: [{
      path: "list-all-products",
      component: () => import('@/views/Endpoints/V1/Products/Product/ListAllProducts.vue')
  },{
      path: "list-all-product-errors",
      component: () => import('@/views/Endpoints/V1/Products/Product/ListAllProductErrors.vue')
  },{
      path: "change-product-state",
      component: () => import('@/views/Endpoints/V1/Products/Product/ChangeProductState.vue')
  },]
    },{
      path: "update",
      component: PassThrough,
      children: [{
      path: "fetch-update-metadata",
      component: () => import('@/views/Endpoints/V1/Products/Update/FetchUpdateMetadata.vue')
  },{
      path: "get-update-by-id",
      component: () => import('@/views/Endpoints/V1/Products/Update/GetUpdateById.vue')
  },{
      path: "download-update-by-id",
      component: () => import('@/views/Endpoints/V1/Products/Update/DownloadUpdateById.vue')
  },]
    },{
      path: "barriers",
      component: PassThrough,
      children: [{
      path: "list-all-barriers",
      component: () => import('@/views/Endpoints/V1/Products/Barriers/ListAllBarriers.vue')
  },{
      path: "get-barrier-by-id",
      component: () => import('@/views/Endpoints/V1/Products/Barriers/GetBarrierById.vue')
  },{
      path: "add-barrier",
      component: () => import('@/views/Endpoints/V1/Products/Barriers/AddBarrier.vue')
  },{
      path: "update-barrier-by-id",
      component: () => import('@/views/Endpoints/V1/Products/Barriers/UpdateBarrierById.vue')
  },{
      path: "delete-barrier-by-id",
      component: () => import('@/views/Endpoints/V1/Products/Barriers/DeleteBarrierById.vue')
  },]
    },{
      path: "lpr-cameras",
      component: PassThrough,
      children: [{
      path: "list-all-lpr-cameras",
      component: () => import('@/views/Endpoints/V1/Products/LprCameras/ListAllLprCameras.vue')
  },{
      path: "get-lpr-camera-by-id",
      component: () => import('@/views/Endpoints/V1/Products/LprCameras/GetLprCameraById.vue')
  },{
      path: "delete-lpr-camera-by-id",
      component: () => import('@/views/Endpoints/V1/Products/LprCameras/DeleteLprCameraById.vue')
  },{
      path: "add-lpr-camera",
      component: () => import('@/views/Endpoints/V1/Products/LprCameras/AddLprCamera.vue')
  },{
      path: "update-lpr-camera-by-id",
      component: () => import('@/views/Endpoints/V1/Products/LprCameras/UpdateLprCameraById.vue')
  },]
    },{
      path: "gates",
      component: PassThrough,
      children: [{
      path: "list-all-gates",
      component: () => import('@/views/Endpoints/V1/Products/Gates/ListAllGates.vue')
  },{
      path: "add-gate",
      component: () => import('@/views/Endpoints/V1/Products/Gates/AddGate.vue')
  },{
      path: "get-gate-by-id",
      component: () => import('@/views/Endpoints/V1/Products/Gates/GetGateById.vue')
  },{
      path: "update-gate-by-id",
      component: () => import('@/views/Endpoints/V1/Products/Gates/UpdateGateById.vue')
  },{
      path: "delete-gate-by-id",
      component: () => import('@/views/Endpoints/V1/Products/Gates/DeleteGateById.vue')
  },]
    },{
      path: "legacy-barriers",
      component: PassThrough,
      children: [{
      path: "list-all-legacy-barriers",
      component: () => import('@/views/Endpoints/V1/Products/LegacyBarriers/ListAllLegacyBarriers.vue')
  },{
      path: "get-legacy--barrier-by-id",
      component: () => import('@/views/Endpoints/V1/Products/LegacyBarriers/GetLegacyBarrierById.vue')
  },{
      path: "add-legacy-barrier",
      component: () => import('@/views/Endpoints/V1/Products/LegacyBarriers/AddLegacyBarrier.vue')
  },{
      path: "update-legacy-barrier-by-id",
      component: () => import('@/views/Endpoints/V1/Products/LegacyBarriers/UpdateLegacyBarrierById.vue')
  },{
      path: "delete-legacy-barrier-by-id",
      component: () => import('@/views/Endpoints/V1/Products/LegacyBarriers/DeleteLegacyBarrierById.vue')
  },]
    },{
      path: "gateways",
      component: PassThrough,
      children: [{
      path: "list-all-gateways",
      component: () => import('@/views/Endpoints/V1/Products/Gateways/ListAllGateways.vue')
  },{
      path: "get-gateway-by-id",
      component: () => import('@/views/Endpoints/V1/Products/Gateways/GetGatewayById.vue')
  },{
      path: "update-gateway-by-id",
      component: () => import('@/views/Endpoints/V1/Products/Gateways/UpdateGatewayById.vue')
  },{
      path: "assign-gateway-to-user",
      component: () => import('@/views/Endpoints/V1/Products/Gateways/AssignGatewayToUser.vue')
  },]
    },]
    },{
      path: "user",
      
      component: PassThrough,
      children: [{
      path: "password-reset",
      component: () => import('@/views/Endpoints/V1/User/PasswordReset.vue')
  },{
      path: "create-user",
      component: () => import('@/views/Endpoints/V1/User/CreateUser.vue')
  },{
      path: "password-reset-confirmation",
      component: () => import('@/views/Endpoints/V1/User/PasswordResetConfirmation.vue')
  },]
    },{
      path: "lot-managment",
      
      component: PassThrough,
      children: [{
      path: "license-plates",
      component: PassThrough,
      children: [{
      path: "list-all-license-plates",
      component: () => import('@/views/Endpoints/V1/LotManagment/LicensePlates/ListAllLicensePlates.vue')
  },{
      path: "get-license-plate-by-id",
      component: () => import('@/views/Endpoints/V1/LotManagment/LicensePlates/GetLicensePlateById.vue')
  },{
      path: "allow-license-plates-to-lot",
      component: () => import('@/views/Endpoints/V1/LotManagment/LicensePlates/AllowLicensePlatesToLot.vue')
  },{
      path: "save-license-plate",
      component: () => import('@/views/Endpoints/V1/LotManagment/LicensePlates/SaveLicensePlate.vue')
  },]
    },{
      path: "lots",
      component: PassThrough,
      children: [{
      path: "list-all-lots",
      component: () => import('@/views/Endpoints/V1/LotManagment/Lots/ListAllLots.vue')
  },{
      path: "get-lot-by-id",
      component: () => import('@/views/Endpoints/V1/LotManagment/Lots/GetLotById.vue')
  },{
      path: "add-new-lot",
      component: () => import('@/views/Endpoints/V1/LotManagment/Lots/AddNewLot.vue')
  },{
      path: "update-lot-by-id",
      component: () => import('@/views/Endpoints/V1/LotManagment/Lots/UpdateLotById.vue')
  },{
      path: "delete-lot-by-id",
      component: () => import('@/views/Endpoints/V1/LotManagment/Lots/DeleteLotById.vue')
  },{
      path: "update-lot-managers-by-lot-id",
      component: () => import('@/views/Endpoints/V1/LotManagment/Lots/UpdateLotManagersByLotId.vue')
  },]
    },{
      path: "lot-zones",
      component: PassThrough,
      children: [{
      path: "list-all-lot-zones",
      component: () => import('@/views/Endpoints/V1/LotManagment/LotZones/ListAllLotZones.vue')
  },{
      path: "get-zone-by-id",
      component: () => import('@/views/Endpoints/V1/LotManagment/LotZones/GetZoneById.vue')
  },{
      path: "add-new-zone",
      component: () => import('@/views/Endpoints/V1/LotManagment/LotZones/AddNewZone.vue')
  },{
      path: "update-zone-by-id",
      component: () => import('@/views/Endpoints/V1/LotManagment/LotZones/UpdateZoneById.vue')
  },{
      path: "delete-zone-by-id",
      component: () => import('@/views/Endpoints/V1/LotManagment/LotZones/DeleteZoneById.vue')
  },]
    },]
    },{
      path: "key-managment",
      
      component: PassThrough,
      children: [{
      path: "reservation-keys",
      component: PassThrough,
      children: [{
      path: "list-all-reservation-keys",
      component: () => import('@/views/Endpoints/V1/KeyManagment/ReservationKeys/ListAllReservationKeys.vue')
  },{
      path: "get-reservation-key-by-id",
      component: () => import('@/views/Endpoints/V1/KeyManagment/ReservationKeys/GetReservationKeyById.vue')
  },{
      path: "delete-reservation-key-by-id",
      component: () => import('@/views/Endpoints/V1/KeyManagment/ReservationKeys/DeleteReservationKeyById.vue')
  },{
      path: "create-reservation-key",
      component: () => import('@/views/Endpoints/V1/KeyManagment/ReservationKeys/CreateReservationKey.vue')
  },]
    },{
      path: "operation-keys",
      component: PassThrough,
      children: [{
      path: "list-all-operation-keys",
      component: () => import('@/views/Endpoints/V1/KeyManagment/OperationKeys/ListAllOperationKeys.vue')
  },{
      path: "get-operation-key-by-id",
      component: () => import('@/views/Endpoints/V1/KeyManagment/OperationKeys/GetOperationKeyById.vue')
  },{
      path: "update-operation-key-by-id",
      component: () => import('@/views/Endpoints/V1/KeyManagment/OperationKeys/UpdateOperationKeyById.vue')
  },{
      path: "bulk-update-operation-keys",
      component: () => import('@/views/Endpoints/V1/KeyManagment/OperationKeys/BulkUpdateOperationKeys.vue')
  },{
      path: "delete-operation-key-by-id",
      component: () => import('@/views/Endpoints/V1/KeyManagment/OperationKeys/DeleteOperationKeyById.vue')
  },{
      path: "create-operation-key",
      component: () => import('@/views/Endpoints/V1/KeyManagment/OperationKeys/CreateOperationKey.vue')
  },{
      path: "create-recurring-operation-key",
      component: () => import('@/views/Endpoints/V1/KeyManagment/OperationKeys/CreateRecurringOperationKey.vue')
  },]
    },{
      path: "weblinks",
      component: PassThrough,
      children: [{
      path: "weblink-operation",
      component: () => import('@/views/Endpoints/V1/KeyManagment/Weblinks/WeblinkOperation.vue')
  },{
      path: "list-all-weblinks",
      component: () => import('@/views/Endpoints/V1/KeyManagment/Weblinks/ListAllWeblinks.vue')
  },{
      path: "get-weblink",
      component: () => import('@/views/Endpoints/V1/KeyManagment/Weblinks/GetWeblink.vue')
  },{
      path: "bulk-update-weblinks",
      component: () => import('@/views/Endpoints/V1/KeyManagment/Weblinks/BulkUpdateWeblinks.vue')
  },{
      path: "create-weblink",
      component: () => import('@/views/Endpoints/V1/KeyManagment/Weblinks/CreateWeblink.vue')
  },{
      path: "delete-weblink",
      component: () => import('@/views/Endpoints/V1/KeyManagment/Weblinks/DeleteWeblink.vue')
  },]
    },{
      path: "reshareable-keys(not-active/not-working,-not-documented)",
      component: PassThrough,
      children: [{
      path: "list-all-reshareable-keys",
      component: () => import('@/views/Endpoints/V1/KeyManagment/ReshareableKeys(notActive/NotWorking,NotDocumented)/ListAllReshareableKeys.vue')
  },{
      path: "get-reshareable-key",
      component: () => import('@/views/Endpoints/V1/KeyManagment/ReshareableKeys(notActive/NotWorking,NotDocumented)/GetReshareableKey.vue')
  },{
      path: "create-reshareable-key-using-operation-key-endpoint",
      component: () => import('@/views/Endpoints/V1/KeyManagment/ReshareableKeys(notActive/NotWorking,NotDocumented)/CreateReshareableKeyUsingOperationKeyEndpoint.vue')
  },{
      path: "create-reshareable-key",
      component: () => import('@/views/Endpoints/V1/KeyManagment/ReshareableKeys(notActive/NotWorking,NotDocumented)/CreateReshareableKey.vue')
  },{
      path: "update-reshareable-key-by-id",
      component: () => import('@/views/Endpoints/V1/KeyManagment/ReshareableKeys(notActive/NotWorking,NotDocumented)/UpdateReshareableKeyById.vue')
  },{
      path: "delete-reshareable-key",
      component: () => import('@/views/Endpoints/V1/KeyManagment/ReshareableKeys(notActive/NotWorking,NotDocumented)/DeleteReshareableKey.vue')
  },]
    },]
    },{
      path: "features",
      
      component: PassThrough,
      children: [{
      path: "list-all-features",
      component: () => import('@/views/Endpoints/V1/Features/ListAllFeatures.vue')
  },]
    },{
      path: "countries",
      
      component: PassThrough,
      children: [{
      path: "list-all-countries",
      component: () => import('@/views/Endpoints/V1/Countries/ListAllCountries.vue')
  },]
    },{
      path: "account-roles",
      
      component: PassThrough,
      children: [{
      path: "list-all-account-roles",
      component: () => import('@/views/Endpoints/V1/AccountRoles/ListAllAccountRoles.vue')
  },]
    },{
      path: "account-user-roles",
      
      component: PassThrough,
      children: [{
      path: "list-all-account-user-roles",
      component: () => import('@/views/Endpoints/V1/AccountUserRoles/ListAllAccountUserRoles.vue')
  },]
    },{
        path: "errors",
        component: () => import('@/views/Endpoints/V1/Errors.vue')
      },{
        path: "enums",
        component: PassThrough,
        children: [{
          path: "accountuserinvitationstatuses",
          component: () => import('@/views/Endpoints/V1/Enums/AccountUserInvitationStatuses.vue')
        },{
          path: "devicetypes",
          component: () => import('@/views/Endpoints/V1/Enums/DeviceTypes.vue')
        },{
          path: "gatewayerrorcodes",
          component: () => import('@/views/Endpoints/V1/Enums/GatewayErrorCodes.vue')
        },{
          path: "gatewaystatuses",
          component: () => import('@/views/Endpoints/V1/Enums/GatewayStatuses.vue')
        },{
          path: "identifiertypes",
          component: () => import('@/views/Endpoints/V1/Enums/IdentifierTypes.vue')
        },{
          path: "keyidentifiertypes",
          component: () => import('@/views/Endpoints/V1/Enums/KeyIdentifierTypes.vue')
        },{
          path: "keyinvitationstatuses",
          component: () => import('@/views/Endpoints/V1/Enums/KeyInvitationStatuses.vue')
        },{
          path: "lotentrydirections",
          component: () => import('@/views/Endpoints/V1/Enums/LotEntryDirections.vue')
        },{
          path: "lotlicenseplatestatuses",
          component: () => import('@/views/Endpoints/V1/Enums/LotLicensePlateStatuses.vue')
        },{
          path: "lprpositions",
          component: () => import('@/views/Endpoints/V1/Enums/LprPositions.vue')
        },{
          path: "parkingtypes",
          component: () => import('@/views/Endpoints/V1/Enums/ParkingTypes.vue')
        },{
          path: "permanentpositions",
          component: () => import('@/views/Endpoints/V1/Enums/PermanentPositions.vue')
        },{
          path: "productstatuses",
          component: () => import('@/views/Endpoints/V1/Enums/ProductStatuses.vue')
        },{
          path: "producttypes",
          component: () => import('@/views/Endpoints/V1/Enums/ProductTypes.vue')
        },{
          path: "recurringdatastatuses",
          component: () => import('@/views/Endpoints/V1/Enums/RecurringDataStatuses.vue')
        },{
          path: "statuses",
          component: () => import('@/views/Endpoints/V1/Enums/Statuses.vue')
        },]},{
        path: "",
        component: () => import('@/views/ApiDescription.vue')
      }]
    },{
      path: "v2",
     
      component: PassThrough,
      children: [{
      path: "auth",
      
      component: PassThrough,
      children: [{
      path: "account-user",
      component: PassThrough,
      children: [{
      path: "pms-login",
      component: () => import('@/views/Endpoints/V2/Auth/AccountUser/PMSLogin.vue')
  },{
      path: "login",
      component: () => import('@/views/Endpoints/V2/Auth/AccountUser/Login.vue')
  },{
      path: "account-logout",
      component: () => import('@/views/Endpoints/V2/Auth/AccountUser/AccountLogout.vue')
  },{
      path: "social-login",
      component: () => import('@/views/Endpoints/V2/Auth/AccountUser/SocialLogin.vue')
  },{
      path: "request-password-reset",
      component: () => import('@/views/Endpoints/V2/Auth/AccountUser/RequestPasswordReset.vue')
  },{
      path: "password-reset",
      component: () => import('@/views/Endpoints/V2/Auth/AccountUser/PasswordReset.vue')
  },]
    },]
    },{
      path: "account",
      
      component: PassThrough,
      children: [{
      path: "list-all-accounts",
      component: () => import('@/views/Endpoints/V2/Account/ListAllAccounts.vue')
  },{
      path: "get-account-by-id",
      component: () => import('@/views/Endpoints/V2/Account/GetAccountById.vue')
  },{
      path: "get-account-users-by-account-id",
      component: () => import('@/views/Endpoints/V2/Account/GetAccountUsersByAccountId.vue')
  },{
      path: "accept-auto-extend",
      component: () => import('@/views/Endpoints/V2/Account/AcceptAutoExtend.vue')
  },{
      path: "update-account-by-id",
      component: () => import('@/views/Endpoints/V2/Account/UpdateAccountById.vue')
  },{
      path: "register-connect-account",
      component: () => import('@/views/Endpoints/V2/Account/RegisterConnectAccount.vue')
  },{
      path: "update-self-account",
      component: () => import('@/views/Endpoints/V2/Account/UpdateSelfAccount.vue')
  },{
      path: "delete-account-by-id",
      component: () => import('@/views/Endpoints/V2/Account/DeleteAccountById.vue')
  },]
    },{
      path: "account-users",
      
      component: PassThrough,
      children: [{
      path: "list-all-account-users",
      component: () => import('@/views/Endpoints/V2/AccountUsers/ListAllAccountUsers.vue')
  },{
      path: "get-account-user-by-id",
      component: () => import('@/views/Endpoints/V2/AccountUsers/GetAccountUserById.vue')
  },{
      path: "get-self-account-user",
      component: () => import('@/views/Endpoints/V2/AccountUsers/GetSelfAccountUser.vue')
  },{
      path: "delete-account-user-by-id",
      component: () => import('@/views/Endpoints/V2/AccountUsers/DeleteAccountUserById.vue')
  },{
      path: "invite-account-user",
      component: () => import('@/views/Endpoints/V2/AccountUsers/InviteAccountUser.vue')
  },{
      path: "request-email-activation",
      component: () => import('@/views/Endpoints/V2/AccountUsers/RequestEmailActivation.vue')
  },{
      path: "email-activation-confirmation",
      component: () => import('@/views/Endpoints/V2/AccountUsers/EmailActivationConfirmation.vue')
  },{
      path: "update-account-user-by-id",
      component: () => import('@/views/Endpoints/V2/AccountUsers/UpdateAccountUserById.vue')
  },{
      path: "update-self-account-user",
      component: () => import('@/views/Endpoints/V2/AccountUsers/UpdateSelfAccountUser.vue')
  },{
      path: "password-reset",
      component: () => import('@/views/Endpoints/V2/AccountUsers/PasswordReset.vue')
  },]
    },{
      path: "account-user-invitations",
      
      component: PassThrough,
      children: [{
      path: "account-user-invitation-confirmation",
      component: () => import('@/views/Endpoints/V2/AccountUserInvitations/AccountUserInvitationConfirmation.vue')
  },{
      path: "list-all-account-user-invitations",
      component: () => import('@/views/Endpoints/V2/AccountUserInvitations/ListAllAccountUserInvitations.vue')
  },{
      path: "resend-account-user-invitation",
      component: () => import('@/views/Endpoints/V2/AccountUserInvitations/ResendAccountUserInvitation.vue')
  },{
      path: "update-account-user-invitation-by-id",
      component: () => import('@/views/Endpoints/V2/AccountUserInvitations/UpdateAccountUserInvitationById.vue')
  },{
      path: "delete-account-user-invitation-by-id",
      component: () => import('@/views/Endpoints/V2/AccountUserInvitations/DeleteAccountUserInvitationById.vue')
  },]
    },{
      path: "license-plates",
      
      component: PassThrough,
      children: [{
      path: "list-all-license-plates",
      component: () => import('@/views/Endpoints/V2/LicensePlates/ListAllLicensePlates.vue')
  },{
      path: "get-license-plate-by-id",
      component: () => import('@/views/Endpoints/V2/LicensePlates/GetLicensePlateById.vue')
  },{
      path: "save-license-plate",
      component: () => import('@/views/Endpoints/V2/LicensePlates/SaveLicensePlate.vue')
  },]
    },{
      path: "licenses",
      
      component: PassThrough,
      children: [{
      path: "license-purchases",
      component: PassThrough,
      children: [{
      path: "list-all-license-purchases",
      component: () => import('@/views/Endpoints/V2/Licenses/LicensePurchases/ListAllLicensePurchases.vue')
  },{
      path: "list-all--license-purchase-history",
      component: () => import('@/views/Endpoints/V2/Licenses/LicensePurchases/ListAllLicensePurchaseHistory.vue')
  },{
      path: "get-license-purchase-by-id",
      component: () => import('@/views/Endpoints/V2/Licenses/LicensePurchases/GetLicensePurchaseById.vue')
  },{
      path: "license-purchase-support",
      component: () => import('@/views/Endpoints/V2/Licenses/LicensePurchases/LicensePurchaseSupport.vue')
  },]
    },]
    },{
      path: "products",
      
      component: PassThrough,
      children: [{
      path: "product",
      component: PassThrough,
      children: [{
      path: "list-all-products",
      component: () => import('@/views/Endpoints/V2/Products/Product/ListAllProducts.vue')
  },{
      path: "list-all-product-errors",
      component: () => import('@/views/Endpoints/V2/Products/Product/ListAllProductErrors.vue')
  },{
      path: "change-product-state",
      component: () => import('@/views/Endpoints/V2/Products/Product/ChangeProductState.vue')
  },{
      path: "change-product-settings",
      component: () => import('@/views/Endpoints/V2/Products/Product/ChangeProductSettings.vue')
  },]
    },{
      path: "update",
      component: PassThrough,
      children: [{
      path: "fetch-update-metadata",
      component: () => import('@/views/Endpoints/V2/Products/Update/FetchUpdateMetadata.vue')
  },{
      path: "get-update-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Update/GetUpdateById.vue')
  },{
      path: "download-update-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Update/DownloadUpdateById.vue')
  },]
    },{
      path: "brains",
      component: PassThrough,
      children: [{
      path: "list-all-brains",
      component: () => import('@/views/Endpoints/V2/Products/Brains/ListAllBrains.vue')
  },{
      path: "get-brain-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Brains/GetBrainById.vue')
  },{
      path: "add-brain",
      component: () => import('@/views/Endpoints/V2/Products/Brains/AddBrain.vue')
  },{
      path: "update-brain-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Brains/UpdateBrainById.vue')
  },{
      path: "delete-brain-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Brains/DeleteBrainById.vue')
  },]
    },{
      path: "barriers",
      component: PassThrough,
      children: [{
      path: "list-all-barriers",
      component: () => import('@/views/Endpoints/V2/Products/Barriers/ListAllBarriers.vue')
  },{
      path: "get-barrier-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Barriers/GetBarrierById.vue')
  },{
      path: "add-barrier",
      component: () => import('@/views/Endpoints/V2/Products/Barriers/AddBarrier.vue')
  },{
      path: "update-barrier-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Barriers/UpdateBarrierById.vue')
  },{
      path: "delete-barrier-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Barriers/DeleteBarrierById.vue')
  },]
    },{
      path: "bollards",
      component: PassThrough,
      children: [{
      path: "list-all-barriers",
      component: () => import('@/views/Endpoints/V2/Products/Bollards/ListAllBarriers.vue')
  },{
      path: "get-barrier-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Bollards/GetBarrierById.vue')
  },{
      path: "add-barrier",
      component: () => import('@/views/Endpoints/V2/Products/Bollards/AddBarrier.vue')
  },{
      path: "update-barrier-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Bollards/UpdateBarrierById.vue')
  },{
      path: "delete-barrier-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Bollards/DeleteBarrierById.vue')
  },{
      path: "list-all-bollards",
      component: () => import('@/views/Endpoints/V2/Products/Bollards/ListAllBollards.vue')
  },{
      path: "add-bollard",
      component: () => import('@/views/Endpoints/V2/Products/Bollards/AddBollard.vue')
  },{
      path: "get-bollard-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Bollards/GetBollardById.vue')
  },{
      path: "update-bollard-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Bollards/UpdateBollardById.vue')
  },{
      path: "delete-bollard-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Bollards/DeleteBollardById.vue')
  },]
    },{
      path: "lpr-cameras",
      component: PassThrough,
      children: [{
      path: "list-all-lpr-cameras",
      component: () => import('@/views/Endpoints/V2/Products/LprCameras/ListAllLprCameras.vue')
  },{
      path: "get-lpr-camera-informations",
      component: () => import('@/views/Endpoints/V2/Products/LprCameras/GetLprCameraInformations.vue')
  },{
      path: "get-lpr-camera-by-id",
      component: () => import('@/views/Endpoints/V2/Products/LprCameras/GetLprCameraById.vue')
  },{
      path: "delete-lpr-camera-by-id",
      component: () => import('@/views/Endpoints/V2/Products/LprCameras/DeleteLprCameraById.vue')
  },{
      path: "change-lpr-camera-state",
      component: () => import('@/views/Endpoints/V2/Products/LprCameras/ChangeLprCameraState.vue')
  },{
      path: "reboot-lpr-camera",
      component: () => import('@/views/Endpoints/V2/Products/LprCameras/RebootLprCamera.vue')
  },{
      path: "update-lpr-camera-by-id",
      component: () => import('@/views/Endpoints/V2/Products/LprCameras/UpdateLprCameraById.vue')
  },{
      path: "assign-account-to-lpr-camera",
      component: () => import('@/views/Endpoints/V2/Products/LprCameras/AssignAccountToLprCamera.vue')
  },]
    },{
      path: "gates",
      component: PassThrough,
      children: [{
      path: "list-all-gates",
      component: () => import('@/views/Endpoints/V2/Products/Gates/ListAllGates.vue')
  },{
      path: "add-gate",
      component: () => import('@/views/Endpoints/V2/Products/Gates/AddGate.vue')
  },{
      path: "get-gate-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Gates/GetGateById.vue')
  },{
      path: "update-gate-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Gates/UpdateGateById.vue')
  },{
      path: "delete-gate-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Gates/DeleteGateById.vue')
  },]
    },{
      path: "chains",
      component: PassThrough,
      children: [{
      path: "list-all-gates",
      component: () => import('@/views/Endpoints/V2/Products/Chains/ListAllGates.vue')
  },{
      path: "add-gate",
      component: () => import('@/views/Endpoints/V2/Products/Chains/AddGate.vue')
  },{
      path: "get-gate-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Chains/GetGateById.vue')
  },{
      path: "update-gate-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Chains/UpdateGateById.vue')
  },{
      path: "delete-gate-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Chains/DeleteGateById.vue')
  },{
      path: "list-all-chains",
      component: () => import('@/views/Endpoints/V2/Products/Chains/ListAllChains.vue')
  },{
      path: "get-chain-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Chains/GetChainById.vue')
  },{
      path: "add-chain",
      component: () => import('@/views/Endpoints/V2/Products/Chains/AddChain.vue')
  },{
      path: "update-chain-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Chains/UpdateChainById.vue')
  },{
      path: "delete-chain-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Chains/DeleteChainById.vue')
  },]
    },{
      path: "legacy-barriers",
      component: PassThrough,
      children: [{
      path: "list-all-legacy-barriers",
      component: () => import('@/views/Endpoints/V2/Products/LegacyBarriers/ListAllLegacyBarriers.vue')
  },{
      path: "get-legacy-barrier-by-id",
      component: () => import('@/views/Endpoints/V2/Products/LegacyBarriers/GetLegacyBarrierById.vue')
  },{
      path: "add-legacy-barrier",
      component: () => import('@/views/Endpoints/V2/Products/LegacyBarriers/AddLegacyBarrier.vue')
  },{
      path: "update-legacy-barrier-by-id",
      component: () => import('@/views/Endpoints/V2/Products/LegacyBarriers/UpdateLegacyBarrierById.vue')
  },{
      path: "delete-legacy-barrier-by-id",
      component: () => import('@/views/Endpoints/V2/Products/LegacyBarriers/DeleteLegacyBarrierById.vue')
  },]
    },{
      path: "gateways",
      component: PassThrough,
      children: [{
      path: "list-all-gateways",
      component: () => import('@/views/Endpoints/V2/Products/Gateways/ListAllGateways.vue')
  },{
      path: "get-gateway-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Gateways/GetGatewayById.vue')
  },{
      path: "update-gateway-by-id",
      component: () => import('@/views/Endpoints/V2/Products/Gateways/UpdateGatewayById.vue')
  },{
      path: "unassign-gateway-from-account",
      component: () => import('@/views/Endpoints/V2/Products/Gateways/UnassignGatewayFromAccount.vue')
  },{
      path: "assign-gateway-to-account",
      component: () => import('@/views/Endpoints/V2/Products/Gateways/AssignGatewayToAccount.vue')
  },]
    },]
    },{
      path: "parking-detection",
      
      component: PassThrough,
      children: [{
      path: "parking-detection-gateways",
      component: PassThrough,
      children: [{
      path: "list-all-parking-detection-gateways",
      component: () => import('@/views/Endpoints/V2/ParkingDetection/ParkingDetectionGateways/ListAllParkingDetectionGateways.vue')
  },{
      path: "get-parking-detection-gateway",
      component: () => import('@/views/Endpoints/V2/ParkingDetection/ParkingDetectionGateways/GetParkingDetectionGateway.vue')
  },{
      path: "update-parking-detection-gateway",
      component: () => import('@/views/Endpoints/V2/ParkingDetection/ParkingDetectionGateways/UpdateParkingDetectionGateway.vue')
  },]
    },{
      path: "parking-detection-cameras",
      component: PassThrough,
      children: [{
      path: "list-all-parking-detection-cameras",
      component: () => import('@/views/Endpoints/V2/ParkingDetection/ParkingDetectionCameras/ListAllParkingDetectionCameras.vue')
  },{
      path: "get-parking-detection-camera",
      component: () => import('@/views/Endpoints/V2/ParkingDetection/ParkingDetectionCameras/GetParkingDetectionCamera.vue')
  },{
      path: "get-camera-frame",
      component: () => import('@/views/Endpoints/V2/ParkingDetection/ParkingDetectionCameras/GetCameraFrame.vue')
  },{
      path: "update-parking-detection-camera",
      component: () => import('@/views/Endpoints/V2/ParkingDetection/ParkingDetectionCameras/UpdateParkingDetectionCamera.vue')
  },]
    },]
    },{
      path: "lot-managment",
      
      component: PassThrough,
      children: [{
      path: "parking-places",
      component: PassThrough,
      children: [{
      path: "list-all-parking-places",
      component: () => import('@/views/Endpoints/V2/LotManagment/ParkingPlaces/ListAllParkingPlaces.vue')
  },{
      path: "get-parking-place",
      component: () => import('@/views/Endpoints/V2/LotManagment/ParkingPlaces/GetParkingPlace.vue')
  },{
      path: "update-parking-place",
      component: () => import('@/views/Endpoints/V2/LotManagment/ParkingPlaces/UpdateParkingPlace.vue')
  },]
    },{
      path: "lot-entries",
      component: PassThrough,
      children: [{
      path: "list-all-lot-entries",
      component: () => import('@/views/Endpoints/V2/LotManagment/LotEntries/ListAllLotEntries.vue')
  },{
      path: "get-lot-entry-by-id",
      component: () => import('@/views/Endpoints/V2/LotManagment/LotEntries/GetLotEntryById.vue')
  },{
      path: "get-lot-entry-image",
      component: () => import('@/views/Endpoints/V2/LotManagment/LotEntries/GetLotEntryImage.vue')
  },{
      path: "update-lot-entry-by-id",
      component: () => import('@/views/Endpoints/V2/LotManagment/LotEntries/UpdateLotEntryById.vue')
  },]
    },{
      path: "lot-license-plates",
      component: PassThrough,
      children: [{
      path: "list-all-lot-license--plates",
      component: () => import('@/views/Endpoints/V2/LotManagment/LotLicensePlates/ListAllLotLicensePlates.vue')
  },{
      path: "allow-license-plates-to-lot",
      component: () => import('@/views/Endpoints/V2/LotManagment/LotLicensePlates/AllowLicensePlatesToLot.vue')
  },{
      path: "update-lot-license-plate",
      component: () => import('@/views/Endpoints/V2/LotManagment/LotLicensePlates/UpdateLotLicensePlate.vue')
  },{
      path: "delete-lot-license-plate",
      component: () => import('@/views/Endpoints/V2/LotManagment/LotLicensePlates/DeleteLotLicensePlate.vue')
  },{
      path: "bulk-delete-lot-license-plates",
      component: () => import('@/views/Endpoints/V2/LotManagment/LotLicensePlates/BulkDeleteLotLicensePlates.vue')
  },]
    },{
      path: "lots",
      component: PassThrough,
      children: [{
      path: "list-all-lots",
      component: () => import('@/views/Endpoints/V2/LotManagment/Lots/ListAllLots.vue')
  },{
      path: "get-lot-by-id",
      component: () => import('@/views/Endpoints/V2/LotManagment/Lots/GetLotById.vue')
  },{
      path: "add-lot",
      component: () => import('@/views/Endpoints/V2/LotManagment/Lots/AddLot.vue')
  },{
      path: "update-lot-by-id",
      component: () => import('@/views/Endpoints/V2/LotManagment/Lots/UpdateLotById.vue')
  },{
      path: "update-lot-matrix",
      component: () => import('@/views/Endpoints/V2/LotManagment/Lots/UpdateLotMatrix.vue')
  },{
      path: "delete-lot-by-id",
      component: () => import('@/views/Endpoints/V2/LotManagment/Lots/DeleteLotById.vue')
  },{
      path: "list-all-parking-managers",
      component: () => import('@/views/Endpoints/V2/LotManagment/Lots/ListAllParkingManagers.vue')
  },{
      path: "list-all-parking-managers",
      component: () => import('@/views/Endpoints/V2/LotManagment/Lots/ListAllParkingManagers.vue')
  },{
      path: "sync-lot",
      component: () => import('@/views/Endpoints/V2/LotManagment/Lots/SyncLot.vue')
  },{
      path: "update-lot-managers-by-lot-id",
      component: () => import('@/views/Endpoints/V2/LotManagment/Lots/UpdateLotManagersByLotId.vue')
  },{
      path: "delete-lot-manager-by-manager-id",
      component: () => import('@/views/Endpoints/V2/LotManagment/Lots/DeleteLotManagerByManagerId.vue')
  },{
      path: "list-lot-managers-by-lot-id",
      component: () => import('@/views/Endpoints/V2/LotManagment/Lots/ListLotManagersByLotId.vue')
  },]
    },{
      path: "lot-zones",
      component: PassThrough,
      children: [{
      path: "list-all-zones",
      component: () => import('@/views/Endpoints/V2/LotManagment/LotZones/ListAllZones.vue')
  },{
      path: "get-zone-by-id",
      component: () => import('@/views/Endpoints/V2/LotManagment/LotZones/GetZoneById.vue')
  },{
      path: "add-new-zone",
      component: () => import('@/views/Endpoints/V2/LotManagment/LotZones/AddNewZone.vue')
  },{
      path: "update-zone-by-id",
      component: () => import('@/views/Endpoints/V2/LotManagment/LotZones/UpdateZoneById.vue')
  },{
      path: "delete-zone-by-id",
      component: () => import('@/views/Endpoints/V2/LotManagment/LotZones/DeleteZoneById.vue')
  },]
    },]
    },{
      path: "key-managment",
      
      component: PassThrough,
      children: [{
      path: "operation-keys",
      component: PassThrough,
      children: [{
      path: "upload-file",
      component: () => import('@/views/Endpoints/V2/KeyManagment/OperationKeys/UploadFile.vue')
  },{
      path: "list-all-operation-keys",
      component: () => import('@/views/Endpoints/V2/KeyManagment/OperationKeys/ListAllOperationKeys.vue')
  },{
      path: "get-operation-key",
      component: () => import('@/views/Endpoints/V2/KeyManagment/OperationKeys/GetOperationKey.vue')
  },{
      path: "create-operation-key",
      component: () => import('@/views/Endpoints/V2/KeyManagment/OperationKeys/CreateOperationKey.vue')
  },{
      path: "create-recurring-operation-keys",
      component: () => import('@/views/Endpoints/V2/KeyManagment/OperationKeys/CreateRecurringOperationKeys.vue')
  },{
      path: "update-operation-key-by-id",
      component: () => import('@/views/Endpoints/V2/KeyManagment/OperationKeys/UpdateOperationKeyById.vue')
  },{
      path: "delete-operation-key",
      component: () => import('@/views/Endpoints/V2/KeyManagment/OperationKeys/DeleteOperationKey.vue')
  },]
    },{
      path: "key-invitations",
      component: PassThrough,
      children: [{
      path: "list-all-key-invitations",
      component: () => import('@/views/Endpoints/V2/KeyManagment/KeyInvitations/ListAllKeyInvitations.vue')
  },{
      path: "list-all-key-invitations-receiver-emails",
      component: () => import('@/views/Endpoints/V2/KeyManagment/KeyInvitations/ListAllKeyInvitationsReceiverEmails.vue')
  },{
      path: "get-key-invitation-by-id",
      component: () => import('@/views/Endpoints/V2/KeyManagment/KeyInvitations/GetKeyInvitationById.vue')
  },{
      path: "update-key-invitation-by-id",
      component: () => import('@/views/Endpoints/V2/KeyManagment/KeyInvitations/UpdateKeyInvitationById.vue')
  },{
      path: "bulk-update-key-invitations",
      component: () => import('@/views/Endpoints/V2/KeyManagment/KeyInvitations/BulkUpdateKeyInvitations.vue')
  },{
      path: "resend-key-invitation",
      component: () => import('@/views/Endpoints/V2/KeyManagment/KeyInvitations/ResendKeyInvitation.vue')
  },{
      path: "delete-key-invitation-by-id",
      component: () => import('@/views/Endpoints/V2/KeyManagment/KeyInvitations/DeleteKeyInvitationById.vue')
  },]
    },{
      path: "key-logs",
      component: PassThrough,
      children: [{
      path: "list-all-key-logs",
      component: () => import('@/views/Endpoints/V2/KeyManagment/KeyLogs/ListAllKeyLogs.vue')
  },{
      path: "list-all-key-logs-receiver-emails",
      component: () => import('@/views/Endpoints/V2/KeyManagment/KeyLogs/ListAllKeyLogsReceiverEmails.vue')
  },{
      path: "get-key-log-by-id",
      component: () => import('@/views/Endpoints/V2/KeyManagment/KeyLogs/GetKeyLogById.vue')
  },{
      path: "update-key-log-by-id",
      component: () => import('@/views/Endpoints/V2/KeyManagment/KeyLogs/UpdateKeyLogById.vue')
  },{
      path: "resend-key-log",
      component: () => import('@/views/Endpoints/V2/KeyManagment/KeyLogs/ResendKeyLog.vue')
  },{
      path: "bulk-update-key-logs",
      component: () => import('@/views/Endpoints/V2/KeyManagment/KeyLogs/BulkUpdateKeyLogs.vue')
  },]
    },{
      path: "weblinks",
      component: PassThrough,
      children: [{
      path: "weblink-metadata",
      component: () => import('@/views/Endpoints/V2/KeyManagment/Weblinks/WeblinkMetadata.vue')
  },{
      path: "weblink-operation",
      component: () => import('@/views/Endpoints/V2/KeyManagment/Weblinks/WeblinkOperation.vue')
  },{
      path: "list-all-weblinks",
      component: () => import('@/views/Endpoints/V2/KeyManagment/Weblinks/ListAllWeblinks.vue')
  },{
      path: "get-weblink-by-id",
      component: () => import('@/views/Endpoints/V2/KeyManagment/Weblinks/GetWeblinkById.vue')
  },{
      path: "create-weblink",
      component: () => import('@/views/Endpoints/V2/KeyManagment/Weblinks/CreateWeblink.vue')
  },{
      path: "update-weblink-by-id",
      component: () => import('@/views/Endpoints/V2/KeyManagment/Weblinks/UpdateWeblinkById.vue')
  },{
      path: "resend-weblink",
      component: () => import('@/views/Endpoints/V2/KeyManagment/Weblinks/ResendWeblink.vue')
  },{
      path: "bulk-update-weblinks",
      component: () => import('@/views/Endpoints/V2/KeyManagment/Weblinks/BulkUpdateWeblinks.vue')
  },{
      path: "delete-weblink-by-id",
      component: () => import('@/views/Endpoints/V2/KeyManagment/Weblinks/DeleteWeblinkById.vue')
  },]
    },{
      path: "operation-tokens",
      component: PassThrough,
      children: [{
      path: "list-all-operation-tokens",
      component: () => import('@/views/Endpoints/V2/KeyManagment/OperationTokens/ListAllOperationTokens.vue')
  },{
      path: "get-operation-token-by-id",
      component: () => import('@/views/Endpoints/V2/KeyManagment/OperationTokens/GetOperationTokenById.vue')
  },{
      path: "create-operation-token",
      component: () => import('@/views/Endpoints/V2/KeyManagment/OperationTokens/CreateOperationToken.vue')
  },{
      path: "update-operation-token-by-id",
      component: () => import('@/views/Endpoints/V2/KeyManagment/OperationTokens/UpdateOperationTokenById.vue')
  },{
      path: "delete-operation-token-by-id",
      component: () => import('@/views/Endpoints/V2/KeyManagment/OperationTokens/DeleteOperationTokenById.vue')
  },]
    },]
    },{
      path: "countries",
      
      component: PassThrough,
      children: [{
      path: "list-all-countries",
      component: () => import('@/views/Endpoints/V2/Countries/ListAllCountries.vue')
  },]
    },{
      path: "account-roles",
      
      component: PassThrough,
      children: [{
      path: "list-all-account-roles",
      component: () => import('@/views/Endpoints/V2/AccountRoles/ListAllAccountRoles.vue')
  },]
    },{
      path: "account-user-roles",
      
      component: PassThrough,
      children: [{
      path: "list-all-account-user-roles",
      component: () => import('@/views/Endpoints/V2/AccountUserRoles/ListAllAccountUserRoles.vue')
  },]
    },{
      path: "statistics",
      
      component: PassThrough,
      children: [{
      path: "get-remote-control-statistics",
      component: () => import('@/views/Endpoints/V2/Statistics/GetRemoteControlStatistics.vue')
  },{
      path: "get-operation-key-statistics",
      component: () => import('@/views/Endpoints/V2/Statistics/GetOperationKeyStatistics.vue')
  },{
      path: "get-key-invitation-statistics",
      component: () => import('@/views/Endpoints/V2/Statistics/GetKeyInvitationStatistics.vue')
  },{
      path: "get-weblinks-statistics",
      component: () => import('@/views/Endpoints/V2/Statistics/GetWeblinksStatistics.vue')
  },{
      path: "get-lot-whitelist-statistics",
      component: () => import('@/views/Endpoints/V2/Statistics/GetLotWhitelistStatistics.vue')
  },{
      path: "get-lot-entry-statistics",
      component: () => import('@/views/Endpoints/V2/Statistics/GetLotEntryStatistics.vue')
  },]
    },{
        path: "errors",
        component: () => import('@/views/Endpoints/V2/Errors.vue')
      },{
        path: "enums",
        component: PassThrough,
        children: [{
          path: "accountuserinvitationstatuses",
          component: () => import('@/views/Endpoints/V2/Enums/AccountUserInvitationStatuses.vue')
        },{
          path: "devicetypes",
          component: () => import('@/views/Endpoints/V2/Enums/DeviceTypes.vue')
        },{
          path: "gatewayerrorcodes",
          component: () => import('@/views/Endpoints/V2/Enums/GatewayErrorCodes.vue')
        },{
          path: "gatewaystatuses",
          component: () => import('@/views/Endpoints/V2/Enums/GatewayStatuses.vue')
        },{
          path: "identifiertypes",
          component: () => import('@/views/Endpoints/V2/Enums/IdentifierTypes.vue')
        },{
          path: "keyidentifiertypes",
          component: () => import('@/views/Endpoints/V2/Enums/KeyIdentifierTypes.vue')
        },{
          path: "keyinvitationstatuses",
          component: () => import('@/views/Endpoints/V2/Enums/KeyInvitationStatuses.vue')
        },{
          path: "lotentrydirections",
          component: () => import('@/views/Endpoints/V2/Enums/LotEntryDirections.vue')
        },{
          path: "lotlicenseplatestatuses",
          component: () => import('@/views/Endpoints/V2/Enums/LotLicensePlateStatuses.vue')
        },{
          path: "lotwhitelistlogoperations",
          component: () => import('@/views/Endpoints/V2/Enums/LotWhitelistLogOperations.vue')
        },{
          path: "lprdevicestatuses",
          component: () => import('@/views/Endpoints/V2/Enums/LprDeviceStatuses.vue')
        },{
          path: "lprpositions",
          component: () => import('@/views/Endpoints/V2/Enums/LprPositions.vue')
        },{
          path: "lprstoragesubscriptionplans",
          component: () => import('@/views/Endpoints/V2/Enums/LprStorageSubscriptionPlans.vue')
        },{
          path: "lprstoragesubscriptionstatuses",
          component: () => import('@/views/Endpoints/V2/Enums/LprStorageSubscriptionStatuses.vue')
        },{
          path: "maxtimeofstayunits",
          component: () => import('@/views/Endpoints/V2/Enums/MaxTimeOfStayUnits.vue')
        },{
          path: "orderbydirections",
          component: () => import('@/views/Endpoints/V2/Enums/OrderByDirections.vue')
        },{
          path: "overstayfeeunits",
          component: () => import('@/views/Endpoints/V2/Enums/OverstayFeeUnits.vue')
        },{
          path: "parkingdetectionlotstatuses",
          component: () => import('@/views/Endpoints/V2/Enums/ParkingDetectionLotStatuses.vue')
        },{
          path: "parkingtypes",
          component: () => import('@/views/Endpoints/V2/Enums/ParkingTypes.vue')
        },{
          path: "permanentpositions",
          component: () => import('@/views/Endpoints/V2/Enums/PermanentPositions.vue')
        },{
          path: "powertypes",
          component: () => import('@/views/Endpoints/V2/Enums/PowerTypes.vue')
        },{
          path: "pricingintervals",
          component: () => import('@/views/Endpoints/V2/Enums/PricingIntervals.vue')
        },{
          path: "productoperations",
          component: () => import('@/views/Endpoints/V2/Enums/ProductOperations.vue')
        },{
          path: "productstatuses",
          component: () => import('@/views/Endpoints/V2/Enums/ProductStatuses.vue')
        },{
          path: "producttypes",
          component: () => import('@/views/Endpoints/V2/Enums/ProductTypes.vue')
        },{
          path: "recurringdatastatuses",
          component: () => import('@/views/Endpoints/V2/Enums/RecurringDataStatuses.vue')
        },{
          path: "statuses",
          component: () => import('@/views/Endpoints/V2/Enums/Statuses.vue')
        },{
          path: "timeranges",
          component: () => import('@/views/Endpoints/V2/Enums/TimeRanges.vue')
        },{
          path: "zonestatuses",
          component: () => import('@/views/Endpoints/V2/Enums/ZoneStatuses.vue')
        },]},{
        path: "",
        component: () => import('@/views/ApiDescription.vue')
      }]
    },{
        path: "",
        component: () => import('@/views/ApiDescription.vue')
      }]
    },
    {
      path:"/changelog",
      component:Changelog,
    }, {
      path: "/ws",
      component: Ws,
      children: [{
        path: "v1",
        component: PassThrough,
        children: [
          {
            path: "setup/connection",
            component: () => import('@/views/Websockets/Setup/Connection.vue')
          },
          {
            path: "setup/events",
            component: () => import('@/views/Websockets/Setup/Events.vue')
          },
          {
            path: "events/product-command",
            component: () => import('@/views/Websockets/Events/ProductCommand.vue')
          },
          {
            path: "events/lot-change",
            component: () => import('@/views/Websockets/Events/LotChange.vue')
          },
          {
            path: "events/gateway-status",
            component: () => import('@/views/Websockets/Events/GatewayStatus.vue')
          },
          {
            path: "events/all-gateways-status",
            component: () => import('@/views/Websockets/Events/AllGatewaysStatus.vue')
          },
          {
            path: "events/gateway-error",
            component: () => import('@/views/Websockets/Events/GatewayError.vue')
          },
          {
            path: "events/operation-key-file-upload-finished",
            component: () => import('@/views/Websockets/Events/OperationKeyFileUploadFinished.vue')
          },
          {
            path: "events/lpr-event",
            component: () => import('@/views/Websockets/Events/LprEvent.vue')
          },
          {
            path: "events/parking-place-change",
            component: () => import('@/views/Websockets/Events/ParkingPlaceChange.vue')
          },
          {
            path: "events/lot-detection-change",
            component: () => import('@/views/Websockets/Events/LotDetectionChange.vue')
          },
          {
            path: "events/parking-gateway-state-change",
            component: () => import('@/views/Websockets/Events/ParkingGatewayStateChange.vue')
          },
        ]
      },{
        path: "",
        component: () => import('@/views/WsDescription.vue')
      }]
    }, {
      path: "",
      component: Api,
      children: [{
        path: "",
        component: () => import('@/views/ApiDescription.vue')
      }
      ]
    },
    {
      path: '/wss',
      redirect: "/ws"
    },
    {
      path: '*',
      redirect: "/api"
    }]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
   scrollBehavior() {
    document.getElementById('app')!.scrollIntoView({
      behavior: "smooth"
    });
  }
});

export default router;
