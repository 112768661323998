







import { Component, Vue } from "vue-property-decorator";
import Nav from "@/components/Nav.vue";
import Sidebar from "@/components/Sidebar.vue";

@Component({
	components: {
		Nav,
		Sidebar,
	},
})
export default class App extends Vue {}
